import * as z from 'zod';

import { cashBackToCardMembershipSchema } from '../membership.schema';

export const cashRedemptionOrderItemDataSchema = z.object({
  productName: z.string(),
  productImageUrl: z.string(),
  displayValue: z.number().optional(),
  amount: z.number(),
  amountInCents: z.number(),
  autosweep: z.boolean(),
  currency: z.string(),
  currencyShortName: z.string(),
  loyaltyProgramId: z.string(),
  // TODO: verify
  membership: cashBackToCardMembershipSchema.optional(),
  partnerUserId: z.string(),
  programShortName: z.string(),
  tenantCurrencyShortName: z.string(),
  processingDelay: z.number(),
});
