import * as z from 'zod';
import { customDataSchema, redemptionEarnSchema } from '../common.schema';
import { referrersEnum } from '../shopping-cart-item.schema';
import {
  baseGiftCardOrderItemDataSchema,
  digitalGiftCardFulfillmentDataSchema,
} from './gift-card';

export const orderItemCampaignDataSchema = z.object({
  id: z.string(),
  qualificationType: z.enum(['all', 'single']),
  discount: z.object({
    type: z.enum(['lumpsum', 'multiplier']),
    percentage: z.number().optional(),
    amount: z.number().optional(),
  }),
  fundingRates: z.object({
    supplier: z.number(),
    fi: z.number(),
  }),
});

export const giftCardOrderItemDataSchema =
  baseGiftCardOrderItemDataSchema.extend({
    fulfillmentData: digitalGiftCardFulfillmentDataSchema.optional(),
    campaign: orderItemCampaignDataSchema.optional(),
  });
export type GiftCardOrderItemData = z.infer<typeof giftCardOrderItemDataSchema>;

// TODO update later
export const membershipInformationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  number: z.string(),
});
export const pointsTransferOrderItemDataSchema = z.object({
  displayValue: z.number().optional(),
  productImageUrl: z.string(),
  autosweep: z.boolean(),
  loyaltyCurrencyName: z.string(),
  loyaltyCurrencyShortName: z.string(),
  loyaltyProgramId: z.string(),
  membership: membershipInformationSchema,
  membershipInformation: membershipInformationSchema,
  redemptionEarn: redemptionEarnSchema.optional(),
  productName: z.string(),
  programShortName: z.string(),
  tenantCurrencyName: z.string(),
  tenantCurrencyShortName: z.string(),
  transferAmount: z.number(),
  processingDelay: z.number(),
  referrer: referrersEnum.optional(),
  customData: customDataSchema.optional(),
  campaign: orderItemCampaignDataSchema.optional(),
});

export const orderItemDataSchema = giftCardOrderItemDataSchema;
