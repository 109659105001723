import { z } from 'zod';
import {
  enrollmentSchema,
  loyaltyProgramCategorySchema,
} from './common.schema';

export const membershipTypeEnum = z.enum([
  'points_transfer_membership',
  'cashback_membership',
]);

export const pointsTransferMembershipSchema = z.object({
  id: z.string(),
  type: membershipTypeEnum.extract(['points_transfer_membership']),
  category: loyaltyProgramCategorySchema.extract([
    'airline',
    'hotel',
    'others',
  ]),
  autosweep: z.boolean(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  loyaltyProgramId: z.string(),
  loyaltyProgramName: z.string(),
  number: z.string(),
  preferred: z.boolean(),
  userId: z.string(),
  dateOfBirth: z.string().optional(),
});

export const cashBackToCardMembershipSchema = z.object({
  id: z.string(),
  type: membershipTypeEnum.extract(['cashback_membership']),
  userId: z.string(),
  loyaltyProgramId: z.string(),
  loyaltyProgramName: z.string(),
  number: z.string(),
  autosweep: z.boolean(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  fullName: z.string().nullable(),
  preferred: z.boolean(),
  category: loyaltyProgramCategorySchema.extract(['cashback_to_card']),
  enrollmentId: z.string(),
  enrollment: enrollmentSchema,
  details: z.object({
    groupName: z.string(),
  }),
});

export const cashBackToAccountMembershipSchema = z.object({
  id: z.string(),
  type: membershipTypeEnum.extract(['cashback_membership']),
  userId: z.string(),
  loyaltyProgramId: z.string(),
  loyaltyProgramName: z.string(),
  number: z.string(),
  autosweep: z.boolean(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  fullName: z.string().nullable(),
  preferred: z.boolean(),
  category: loyaltyProgramCategorySchema.extract(['cashback_to_account']),
  enrollmentId: z.string().nullable(),
  enrollment: z.null(),
  details: z.object({
    bsbNumber: z.string(),
  }),
});

export const cashBackMembershipSchema = z.object({
  id: z.string(),
  type: membershipTypeEnum.extract(['cashback_membership']),
  attributes: z.discriminatedUnion('category', [
    cashBackToAccountMembershipSchema,
    cashBackToCardMembershipSchema,
  ]),
});

export const membershipSchema = z.union([
  cashBackToCardMembershipSchema,
  pointsTransferMembershipSchema,
  cashBackToAccountMembershipSchema,
]);

export const membershipsSchema = z.array(membershipSchema);

export type PointsTransferMembership = z.infer<
  typeof pointsTransferMembershipSchema
>;

export type CashBackToCardMembership = z.infer<
  typeof cashBackToCardMembershipSchema
>;

export type CashBackToAccountMembership = z.infer<
  typeof cashBackToAccountMembershipSchema
>;

export type Membership =
  | PointsTransferMembership
  | CashBackToCardMembership
  | CashBackToAccountMembership;

export const membershipsResponseSchema = z.object({
  data: membershipsSchema,
  meta: z.object({ count: z.number() }),
});

export type MembershipResponse = z.infer<typeof membershipsResponseSchema>;
