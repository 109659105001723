import * as z from 'zod';
import { customDataSchema, redemptionEarnSchema } from '../common.schema';
import { orderAddressSchema } from './common';

export const baseGiftCardOrderItemDataSchema = z.object({
  amount: z.number(),
  cardId: z.string(),
  currency: z.string(),
  productName: z.string(),
  originalProductName: z.string().optional(),
  productImageUrl: z.string(),
  redemptionEarn: redemptionEarnSchema.optional(),
  supplier: z.string(),
  displayValue: z.number(),
  originalCardId: z.string().optional(),
  supplierReferenceId: z.string().optional(),
  recipient: z.object({
    address: orderAddressSchema.optional().nullable(),
    companyName: z.string().optional().nullable(),
    message: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    firstName: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
  }),
  fulfillmentUrl: z.string().optional(),
  customData: customDataSchema.optional(),
});

export const digitalGiftCardFulfillmentDataSchema = z.object({
  downloadUrl: z.string(),
  fulfillmentUrl: z.string().optional(),
  receiptCode: z.string(),
  redemptionUrl: z.string(),
  viewUrl: z.string(),
});

export const giftCardMetadataSchema = z.object({
  amount: z.number(),
  cashAmount: z.number().optional(),
  currency: z.string(),
  productName: z.string(),
  supplier: z.string(),
  lastName: z.string().optional(),
  firstName: z.string().optional(),
  phoneNumber: z.string().optional(),
  email: z.string().optional(),
  recipient: z
    .object({
      lastName: z.string().optional(),
      firstName: z.string().optional(),
      phoneNumber: z.string().optional().nullable(),
      email: z.string().optional(),
    })
    .optional(),
  fulfillmentData: z.array(
    digitalGiftCardFulfillmentDataSchema,
    // enable once we support physical gift cards
    // physicalGiftCardFulfillmentDataSchema,
  ),
  productImageUrl: z.string().url(),
});
