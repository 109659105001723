import * as z from 'zod';

export const enrollmentStatusEnum = z.enum([
  'active',
  'closed',
  'blocked',
  'write_off',
]);

export const loyaltyProgramCategorySchema = z.enum([
  'airline',
  'hotel',
  'cashback_to_card',
  'cashback_to_account',
  'status_upgrade',
  'others',
]);

export const enrollmentSchema = z.object({
  id: z.string(),
  groupName: z.string(),
  status: enrollmentStatusEnum,
  productCategory: z.string().optional(),
  productCode: z.string().optional(),
});

export const redemptionEarnSchema = z.object({
  pointsEarned: z.number(),
  earnRate: z.number(),
});

export const customDataSchema = z.object({
  checkoutTerms: z
    .array(
      z.object({
        title: z.string(),
        body: z.string(),
      }),
    )
    .optional(),
});
