import * as z from 'zod';

export const flightRule = z.object({ category: z.string(), rule: z.string() });

export const flightTermAndConditionsSchema = z.object({
  completed: z.boolean(),
  rules: z.array(flightRule),
});

export type FlightRule = z.infer<typeof flightRule>;

export type FlightTermAndConditions = z.infer<
  typeof flightTermAndConditionsSchema
>;
