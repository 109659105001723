import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';
import { getPathFromLoyaltyProgram } from '@/features/points-transfer/utils';
import { cn } from '@/utils/tailwind';
import { useProductTypeToRecommendationItemTitle } from '../hooks/use-product-type-to-recommendation-item-title';
import { LoyaltyProgramTypeMapper, RecommendedProduct } from '../types';
import {
  RecommendedBannerProps,
  isGiftCard,
  isLoyaltyProgram,
} from './recommended-banner';
import { RecommendedProductV2 } from './recommended-product-v2';

export function RecommendedBannerV2({
  title,
  recommendedProducts,
  results,
  onClick,
  className,
}: RecommendedBannerProps) {
  const productTypeToRecommendationItemTitle =
    useProductTypeToRecommendationItemTitle();

  return (
    <HomepageProductsCarousel
      className={cn('gap-4 container-responsive lg:gap-6', className)}
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4 lg:gap-6"
      titleComponent={
        <p className="font-heading text-base-bold md:text-xl-bold">{title}</p>
      }
    >
      {results.map(({ data }, idx) => {
        if (!data || typeof data === 'function') {
          return null;
        }

        const { id, type, score } = recommendedProducts[idx];
        const recommendedProduct: RecommendedProduct = {
          id,
          type,
          score,
          ranking: idx,
        };

        const handleClick = <T extends { name: string }>(product: T) => {
          onClick?.({
            id_0: id,
            name_0: product.name,
            ranking: idx,
            score_0: score,
            type_0: type,
          });
        };

        let productImageUrl = '';
        let productLink = '';

        if (isGiftCard(data)) {
          productImageUrl = data.imageUrls[0];
          productLink = `/products/gift-cards/${data.id}`;
        }

        if (isLoyaltyProgram(data)) {
          productImageUrl =
            data.capabilities[0].secondaryLogoUrl ??
            data.capabilities[0].logoUrl;
          productLink =
            getPathFromLoyaltyProgram(LoyaltyProgramTypeMapper[type]!) +
            data.id;
        }

        return (
          <CarouselItem
            key={`carousel-item-${idx}`}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <RecommendedProductV2
              onClick={() => handleClick(data)}
              className="gtm:rfy shrink-0 gap-2"
              recommendedProduct={recommendedProduct}
              productName={data.name}
              productType={
                productTypeToRecommendationItemTitle[recommendedProduct.type]
              }
              productImageUrl={productImageUrl}
              productLink={productLink}
            />
          </CarouselItem>
        );
      })}
    </HomepageProductsCarousel>
  );
}
