'use client';

import { useTranslations } from 'next-intl';
import { useContext } from 'react';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';
import { Button } from '../button/button';
import { CarouselContext } from './carousel';

interface CarouselArrowWithPaginationProps {
  currentIndex?: number;
  className?: string;
  showPagination?: boolean;
  hideOnNotSlidable?: boolean;
}

export function CarouselArrowWithPagination({
  currentIndex,
  className,
  showPagination = false,
  hideOnNotSlidable = false,
}: CarouselArrowWithPaginationProps) {
  const t = useTranslations('sharedCarousel');
  const {
    prevBtnDisabled,
    onPrevButtonClick,
    nextBtnDisabled,
    onNextButtonClick,
    scrollSnaps,
    selectedIndex: indexFromCarousel,
  } = useContext(CarouselContext);

  // We prefer using currentIndex if it's provided as it's more accurate
  // If we have multiple pagination components in each carousel item
  // indexFromCarousel will shown the same index for all of them
  const carouselItemPageNumber = (currentIndex || indexFromCarousel) + 1;

  const previousSlideText = t('previousSlide');
  const paginationText = t('pagination_currentPage_totalPages', {
    currentPage: carouselItemPageNumber,
    totalPages: scrollSnaps.length,
  });
  const nextSlideText = t('nextSlide');

  return (
    <div
      className={cn(
        'flex w-fit flex-row items-center justify-center gap-2 rounded-custom bg-neutral-100 p-1',
        { hidden: hideOnNotSlidable && scrollSnaps.length <= 1 },
        className,
      )}
    >
      <Button
        aria-label={previousSlideText}
        icon="iconOnly"
        isDisabled={prevBtnDisabled}
        onPress={onPrevButtonClick}
        size="icon"
        noBorder
        variant={null}
        className={cn('h-6 w-6 focus:ring-0', {
          'text-neutral-300': prevBtnDisabled,
        })}
      >
        <Icon name="chevron-left" className="h-4 w-4" />
      </Button>

      {showPagination ? (
        <p className={cn('font-normal text-neutral-600')}>
          <span aria-hidden="true">
            {carouselItemPageNumber}/{scrollSnaps.length}
          </span>
          <span className="sr-only">{paginationText}</span>
        </p>
      ) : null}

      <Button
        aria-label={nextSlideText}
        icon="iconOnly"
        isDisabled={nextBtnDisabled}
        onPress={onNextButtonClick}
        size="icon"
        noBorder
        variant={null}
        className={cn('h-6 w-6 focus:ring-0', {
          'text-neutral-300': nextBtnDisabled,
        })}
      >
        <Icon name="chevron-right" className="h-4 w-4" />
      </Button>
    </div>
  );
}
