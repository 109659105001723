'use client';

import { type ComponentProps } from 'react';

import {
  getMaxDenomination,
  getMinDenomination,
} from '@/app/[locale]/products/gift-cards/utils';
import ProductTile1 from '@/design-system-components/product-tile-1/product-tile-1';
import { GiftCard } from '@/features/gift-card/gift-card.schema';
import type {
  RecommendedBannerItemGtmProps,
  RecommendedProduct,
} from '../types';

interface RecommendedGiftCardOnClickInit extends GiftCard {}

interface RecommendedGiftCardType
  extends Pick<ComponentProps<'div'>, 'className'> {
  recommendedProduct?: RecommendedProduct;
  stylesClasses: string;
  onClick?(init: RecommendedGiftCardOnClickInit): void;
  giftCard: GiftCard;
}

export function RecommendedGiftCard({
  className,
  recommendedProduct,
  stylesClasses,
  onClick,
  giftCard,
}: RecommendedGiftCardType) {
  const { id, name, image, minPrice, maxPrice, minRedemption } = {
    ...giftCard,
    image: giftCard.imageUrls[0],
    maxPrice: getMaxDenomination(giftCard).displayValue,
    minPrice: getMinDenomination(giftCard).displayValue,
    minRedemption: getMinDenomination(giftCard).price,
  };
  const dataGtm = recommendedProduct
    ? ({
        id_0: recommendedProduct.id,
        name_0: name,
        ranking: recommendedProduct.ranking,
        score_0: recommendedProduct.score,
        type_0: recommendedProduct.type,
      } as const satisfies RecommendedBannerItemGtmProps)
    : undefined;

  const handleClick = () => {
    onClick?.(giftCard);
  };

  return (
    <div className={stylesClasses}>
      <ProductTile1
        onClick={handleClick}
        className={className}
        dataGtm={JSON.stringify(dataGtm)}
        data-testid="gift-card-item"
        productName={name}
        denominationRange={[minPrice, maxPrice]}
        minimumRedemption={minRedemption}
        link={`/products/gift-cards/${id}`}
        imageUrl={image}
      />
    </div>
  );
}
