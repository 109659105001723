'use client';

import { cn } from '@/utils/tailwind';
import { cva } from 'class-variance-authority';
import { HTMLAttributes, forwardRef, useContext } from 'react';
import { CarouselContext } from './carousel';

const wrapperVariants = cva('', {
  variants: {
    styling: {
      variant2: 'gap-[6px]',
      variant1: 'gap-2',
    },
  },
  defaultVariants: {
    styling: 'variant1',
  },
});

const circleButtonVariants = cva(
  'transition duration-300 ease-in rounded-full bg-primary-300',
  {
    variants: {
      styling: {
        variant2: 'h-[6px] w-[6px]',
        variant1: 'h-2 w-2',
      },
    },
    defaultVariants: {
      styling: 'variant1',
    },
  },
);

const chosenDotVariants = cva('rounded bg-primary', {
  variants: {
    styling: {
      variant2: 'w-4 h-[6px]',
      variant1: 'w-4 h-2',
    },
  },
  defaultVariants: {
    styling: 'variant1',
  },
});

const CarouselDotButtons = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    wrapperClassName?: string;
    variant?: 'variant2' | 'variant1';
    hideOnNotSlidable?: boolean;
  }
>(
  (
    {
      className,
      wrapperClassName,
      variant = 'variant1',
      hideOnNotSlidable = false,
      ...props
    },
    ref,
  ) => {
    const { scrollSnaps, onDotButtonClick, selectedIndex } =
      useContext(CarouselContext);

    return (
      <div
        className={cn(
          'mt-4 flex flex-row justify-center',
          wrapperVariants({ styling: variant }),
          wrapperClassName,
          { hidden: hideOnNotSlidable && scrollSnaps.length <= 1 },
        )}
        ref={ref}
        {...props}
      >
        {scrollSnaps.map((_, index) => (
          <button
            title="dot button"
            key={`dot-button-${index}`}
            type="button"
            onClick={() => onDotButtonClick(index)}
            className={cn(
              circleButtonVariants({ styling: variant }),
              {
                [chosenDotVariants({ styling: variant })]:
                  index === selectedIndex,
              },
              className,
            )}
          />
        ))}
      </div>
    );
  },
);
CarouselDotButtons.displayName = 'CarouselDotButtons';

export { CarouselDotButtons };
