'use client';

import { ImageWithFallbackSvg } from '@/design-system-components/default-image/image-with-fallback-svg';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { cn } from '@/utils/tailwind';
import React, { ComponentProps, useState } from 'react';

interface ImageWithFallbackProps extends ComponentProps<'img'> {
  fallbackSrc?: string;
  ignoreError?: boolean;
  fallbackClassName?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  alt = '',
  children,
  fallbackSrc,
  ignoreError = false,
  src,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const { sentryLog } = useSentryMonitoring();

  const handleError = () => {
    setHasError(true);
    if (!ignoreError) {
      sentryLog.error(`error while loading ${src}`);
    }
  };

  if (hasError || !src) {
    if (fallbackSrc) {
      return (
        <ImageWithFallback
          src={fallbackSrc}
          ignoreError={ignoreError}
          alt={alt}
          {...props}
        />
      );
    }

    if (children) return children;

    return (
      <div
        className={cn(
          'flex h-full w-full items-center justify-center rounded bg-neutral-300',
          props.className,
          props.fallbackClassName,
        )}
      >
        <ImageWithFallbackSvg />
      </div>
    );
  }

  return <img src={src} {...props} onError={handleError} alt={alt} />;
};

export default ImageWithFallback;
