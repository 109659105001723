'use client';

import { GiftCard } from '@/features/gift-card/gift-card.schema';
import useLoyaltyProgramByTypeAndId from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import { cn } from '@/utils/tailwind';
import { useRecommendedProducts } from '../hooks/use-recommended-products';
import {
  LoyaltyProgramTypeMapper,
  RecommendedProduct,
  RecommendedProductsBannerVariants,
  RecommendedProductsData,
  type RecommendedBannerItemGtmProps,
} from '../types';
import { RecommendedGiftCard } from './recommended-gift-card';
import { RecommendedLoyaltyProgram } from './recommended-loyalty-program';

export interface RecommendedBannerProps {
  title: string;
  recommendedProducts: RecommendedProductsData;
  results: ReturnType<typeof useRecommendedProducts>;
  variant?: RecommendedProductsBannerVariants;
  onClick?(init: RecommendedBannerItemGtmProps): void;
  className?: string;
}

export function isGiftCard(
  product:
    | ReturnType<typeof useLoyaltyProgramByTypeAndId>['loyaltyProgram']
    | GiftCard,
): product is GiftCard {
  return product ? product.type === 'gift_card' : false;
}

export function isLoyaltyProgram(
  product:
    | ReturnType<typeof useLoyaltyProgramByTypeAndId>['loyaltyProgram']
    | GiftCard,
): product is ReturnType<
  typeof useLoyaltyProgramByTypeAndId
>['loyaltyProgram'] {
  return product ? product.type === 'loyalty_program' : false;
}

export function RecommendedBanner({
  title,
  results,
  recommendedProducts,
  onClick,
  variant = 'product_details',
}: RecommendedBannerProps) {
  const stylesClasses = cn(
    'basis-full',
    variant === 'confirmation' && 'lg:min-w-[230px]',
    variant === 'product_details' && 'lg:max-w-[230px]',
  );

  return (
    <div className="my-6 container-responsive lg:my-8">
      <h2 className="mb-6 font-heading text-base-bold md:text-xl-bold lg:mb-8">
        {title}
      </h2>
      <div
        className={cn(
          'flex flex-col gap-4 overflow-auto lg:flex-row',
          '[&>img]:h-[115px] [&>img]:w-[184px]',
        )}
      >
        {results.map(({ data }, idx) => {
          if (!data || typeof data === 'function') {
            return null;
          }

          const { id, type, score } = recommendedProducts[idx];
          const recommendedProduct: RecommendedProduct = {
            id,
            type,
            score,
            ranking: idx,
          };

          const handleClick = <T extends { name: string }>(product: T) => {
            onClick?.({
              id_0: id,
              name_0: product.name,
              ranking: idx,
              score_0: score,
              type_0: type,
            });
          };

          if (isGiftCard(data)) {
            return (
              <RecommendedGiftCard
                onClick={handleClick}
                className="gtm:rfy"
                key={idx}
                recommendedProduct={recommendedProduct}
                stylesClasses={stylesClasses}
                giftCard={data}
              />
            );
          }

          if (isLoyaltyProgram(data)) {
            return (
              <RecommendedLoyaltyProgram
                className="gtm:rfy"
                key={idx}
                recommendedProduct={recommendedProduct}
                stylesClasses={stylesClasses}
                loyaltyProgramId={id}
                loyaltyProgramType={LoyaltyProgramTypeMapper[type]!}
                onClick={handleClick}
                loyaltyProgram={data}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
