import { useFetch } from '@/hooks/fetch/use-fetch';
import { giftCardByIdOptions } from '@/hooks/gift-cards/use-gift-cards';
import { loyaltyProgramByTypeAndIdOptions } from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import {
  LoyaltyProgramTypeMapper,
  RecommendedProductsData,
  RecommendedProductsDataItemTypes,
} from '../types';

export function useRecommendedProducts(
  recommendedProducts: RecommendedProductsData,
  enabled = true,
) {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  return useQueries({
    queries: recommendedProducts.map((product) => {
      if (product.type === RecommendedProductsDataItemTypes.GiftCard) {
        return giftCardByIdOptions({
          id: product.id,
          fetch,
          queryClient,
          enabled,
        });
      }

      return loyaltyProgramByTypeAndIdOptions({
        id: product.id,
        loyaltyProgramType: LoyaltyProgramTypeMapper[product.type]!,
        fetch,
        queryClient,
        enabled,
      });
    }),
  });
}
