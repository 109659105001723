import { useTranslations } from 'next-intl';
import type { ComponentProps } from 'react';
import { useNumberFormatter } from 'react-aria';

import { RcLink } from '@/components/rc-link';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { useTrackGiftCardClick } from '@/hooks/amplitude/gift-cards/use-track-amplitude-gift-card';
import { useCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import {
  PRODUCT_TILE_1_IMAGE_HEIGHT,
  PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE,
  PRODUCT_TILE_1_IMAGE_WIDTH,
  PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE,
} from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

interface ProductTile1Props
  extends Pick<ComponentProps<typeof RcLink>, 'className' | 'onClick'> {
  dataGtm?: string;
  link: string;
  imageUrl: string;
  productName: string;
  denominationRange: [number, number];
  minimumRedemption: number;
  renderCampaignLabel?: () => React.ReactNode;
}

/**
 * Gift Card and Merch Products
 */
export default function ProductTile1(props: ProductTile1Props) {
  const t = useTranslations('productTitle1');
  const currency = useCurrency();
  const numberFormatter = useNumberFormatter();
  const isMobile = useIsMobile();
  const trackGiftCardClick = useTrackGiftCardClick();

  const denominationText =
    currency?.symbol &&
    props.denominationRange?.[0] &&
    props.denominationRange?.[1]
      ? t('denomination_from_to', {
          from: `${currency.symbol}${numberFormatter.format(
            props.denominationRange[0],
          )}`,
          to: `${currency.symbol}${numberFormatter.format(
            props.denominationRange[1],
          )}`,
        })
      : '';

  const imageHeight = isMobile
    ? PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE
    : PRODUCT_TILE_1_IMAGE_HEIGHT;
  const imageWidth = isMobile
    ? PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE
    : PRODUCT_TILE_1_IMAGE_WIDTH;

  function renderDenominationRange(low: number, high: number) {
    if (low === high) {
      return (
        <>{`${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[0],
        )}`}</>
      );
    }
    return (
      <>
        {`${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[0],
        )} - ${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[1],
        )}`}
      </>
    );
  }

  function getDenominationRangeAriaLabel(low: number, high: number) {
    if (low === high) {
      return `${currency ? currency.symbol : ''}${numberFormatter.format(
        props.denominationRange[0],
      )}`;
    }
    return denominationText;
  }

  return (
    <RcLink
      data-gtm={props.dataGtm}
      href={props.link}
      className={cn('rounded-lg', props.className)}
      onClick={(event) => {
        trackGiftCardClick('reward', {
          name: props.productName,
        });
        if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div className="flex h-full flex-col items-center rounded-lg border border-neutral-400 bg-white hover:border-black">
        <div className="relative flex max-h-[179px] w-full items-center justify-center border-b border-neutral-400 p-6">
          <ImageWithFallback
            src={props.imageUrl}
            alt=""
            height={imageHeight}
            width={imageWidth}
            className={cn(
              'mx-auto rounded-lg object-contain drop-shadow-sm',
              `h-[${PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE}px] w-[${PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE}px]`,
              `lg:h-[${PRODUCT_TILE_1_IMAGE_HEIGHT}px] lg:w-[${PRODUCT_TILE_1_IMAGE_WIDTH}px]`,
            )}
            ignoreError
          />
          {props.renderCampaignLabel ? props.renderCampaignLabel() : null}
        </div>
        <div className="flex h-full w-full flex-col justify-between px-4 py-[20px] lg:p-6">
          <div>
            <h2 className="mb-2 font-normal tracking-tight text-neutral-600">
              {props.productName}
            </h2>
            <p className="mb-3 font-bold text-black ">
              <span
                aria-label={getDenominationRangeAriaLabel(
                  props.denominationRange[0],
                  props.denominationRange[1],
                )}
              >
                {renderDenominationRange(
                  props.denominationRange[0],
                  props.denominationRange[1],
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </RcLink>
  );
}
