export type GCViewData = {
  id: string;
  image: string;
  name: string;
  category: string;
  maxPrice: number;
  minPrice: number;
  minRedemption: number;
};
export enum GiftCardFilter {
  All = 'All',
  MallsAndHyperMarkets = 'malls_and_hypermarkets',
  Electronics = 'electronics',
  Fashion = 'fashion',
  BeautyAndHealth = 'beauty_and_health',
  HomeAndGarden = 'home_and_garden',
  Travel = 'travel',
  Entertainment = 'entertainment',
  Dining = 'dining',
  OnlineShopping = 'online_shopping',
  YoungOnes = 'young_ones',
  Experiences = 'experiences',
  Jewellery = 'jewellery',
  Transport = 'transport',
  Others = 'others',
}
