'use client';

import { type ComponentProps } from 'react';

import ProductTile2 from '@/design-system-components/product-tile-2/product-tile-2';
import { getPathFromLoyaltyProgram } from '@/features/points-transfer/utils';
import useLoyaltyProgramByTypeAndId from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import { LoyaltyProgramType } from '@/schema/loyalty-program.schema';
import {
  getCapabilityConfig,
  getCapabilityForLoyaltyProgramType,
} from '@/utils/get-capability';
import type {
  RecommendedBannerItemGtmProps,
  RecommendedProduct,
} from '../types';

interface RecommendedLoyaltyProgramType
  extends Pick<ComponentProps<'div'>, 'className'> {
  stylesClasses: string;
  loyaltyProgramId: string;
  loyaltyProgramType: LoyaltyProgramType;
  recommendedProduct?: RecommendedProduct;
  onClick?(
    product: NonNullable<
      ReturnType<typeof useLoyaltyProgramByTypeAndId>['loyaltyProgram']
    >,
  ): void;
  loyaltyProgram: NonNullable<
    ReturnType<typeof useLoyaltyProgramByTypeAndId>['loyaltyProgram']
  >;
}

export function RecommendedLoyaltyProgram({
  className,
  loyaltyProgramId,
  stylesClasses,
  loyaltyProgramType,
  onClick,
  recommendedProduct,
  loyaltyProgram,
}: RecommendedLoyaltyProgramType) {
  const { capabilities, loyaltyCurrency, name, redeemable, transferRate } =
    loyaltyProgram;
  const capability = getCapabilityForLoyaltyProgramType(
    loyaltyProgramType,
    capabilities,
  );
  const dataGtm = recommendedProduct
    ? ({
        id_0: recommendedProduct.id,
        name_0: name,
        ranking: recommendedProduct.ranking,
        score_0: recommendedProduct.score,
        type_0: recommendedProduct.type,
      } as const satisfies RecommendedBannerItemGtmProps)
    : undefined;

  const handleClick = () => {
    onClick?.(loyaltyProgram);
  };

  return (
    <div className={stylesClasses}>
      <ProductTile2
        className={className}
        dataGtm={JSON.stringify(dataGtm)}
        partnerCurrencyName={loyaltyCurrency?.name}
        deliveryDuration={capability?.processingDelay}
        productName={name}
        minimumRedemption={transferRate?.minimumTransfer}
        targetMinimumRedemption={transferRate?.targetMinimumTransfer}
        denomination={transferRate?.denomination}
        targetDenomination={transferRate?.targetDenomination}
        link={getPathFromLoyaltyProgram(loyaltyProgramType) + loyaltyProgramId}
        imageUrl={capability?.secondaryLogoUrl ?? capability?.logoUrl ?? ''}
        redeemable={redeemable}
        discountPercentage={
          getCapabilityConfig('discountPercentage', capability) ?? 0
        }
        showLiveDemoBadge={loyaltyProgramType === 'points_transfer'}
        onClick={handleClick}
      />
    </div>
  );
}
