import { useTranslations } from 'next-intl';
import { useMemo } from 'react';
import { RecommendedProductsDataItemTypes } from '../types';

export type ProductTypeToRecommendationItemTitle = {
  [key in RecommendedProductsDataItemTypes]: string;
};

export function useProductTypeToRecommendationItemTitle() {
  const t = useTranslations('productTypeToRecommendationItemTitle');

  const pointsTransferText = t('pointsTransfer');
  const cryptoText = t('crypto');
  const giftCardText = t('giftCard');
  const cashRedemptionText = t('cashRedemption');
  const sustainabilityText = t('sustainability');
  const voucherText = t('voucher');

  const titles: ProductTypeToRecommendationItemTitle = useMemo(() => {
    return {
      PointsTransfer: pointsTransferText,
      Crypto: cryptoText,
      GiftCard: giftCardText,
      CashRedemption: cashRedemptionText,
      Sustainability: sustainabilityText,
      Voucher: voucherText,
    };
  }, [
    cashRedemptionText,
    cryptoText,
    giftCardText,
    pointsTransferText,
    sustainabilityText,
    voucherText,
  ]);

  return titles;
}
