import { ComponentProps } from 'react';

interface ImageWithFallbackSvgProps
  extends Pick<ComponentProps<'svg'>, 'height' | 'width'> {}

export function ImageWithFallbackSvg({
  width = '50%',
  height = '50%',
}: ImageWithFallbackSvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 103 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.1304 11.3978H99.2823H100.246C101.532 11.6141 102.5 12.8098 102.5 14.1116V14.438V76.8538V77.829C102.286 79.0248 101.214 79.8897 100.032 80H99.3871H15.1927C14.3338 80 13.5838 79.8898 13.0476 79.347C12.5113 78.8043 12.084 78.1512 12.084 77.3923V76.8495V14.3231C12.084 13.8906 12.084 13.4538 12.2976 13.0214C12.725 11.8256 13.6928 11.2828 14.87 11.1769H15.4063H57.3448L57.1304 11.3978ZM17.5515 57.4205C17.6604 57.3102 17.7652 57.2042 17.8741 57.094C23.6641 51.1238 29.5627 45.2643 35.3576 39.2941C35.5713 39.0778 35.7849 38.8616 35.8939 38.7513C37.3937 37.5556 39.0025 37.0128 40.8293 37.4496C42.0108 37.6659 42.8654 38.3189 43.7243 39.0779L64.6388 60.2449C64.7477 60.3552 64.8525 60.4612 64.9614 60.6774C65.1751 60.4612 65.3888 60.2449 65.6066 60.0244L73.6507 51.8833C75.1505 50.3653 76.8683 49.8226 78.9044 50.1447C80.0859 50.361 81.0495 51.014 81.9084 51.8833L91.3476 61.4365C93.0654 63.175 94.6742 64.8032 96.3877 66.5374L96.7103 66.8639V17.1473H17.5514L17.5515 57.4205ZM91.0206 5.64364V5.5334V2.92996C91.0206 1.95471 90.5932 1.19143 89.7344 0.542745C89.0892 0.110242 88.4482 0 87.6982 0H3.82664H3.39929C2.75409 0 2.1131 0.216251 1.68151 0.652987C0.822642 1.30598 0.5 2.17094 0.5 3.25643V65.5614V65.7777C0.5 67.4059 1.89516 68.7076 3.50397 68.7076H5.8627H6.1853V5.64279H91.0254L91.0206 5.64364ZM82.5492 24.313C78.6863 24.313 75.4687 27.5695 75.4687 31.479C75.4687 35.3885 78.5774 38.6449 82.5492 38.6449C86.5167 38.6449 89.6297 35.4988 89.6297 31.479C89.6297 27.5695 86.521 24.313 82.5492 24.313Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}
